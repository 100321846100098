/* ==========================================================================
   # COVER
   ========================================================================== */

/**
 * A flex-based design pattern that handles vertically centered content.
 *
 * @link https://every-layout.dev/layouts/cover/
 */

.o-cover {
  display: flex;
  flex-direction: column;
  min-block-size: 100vh;
  padding: 0;
}

.o-cover > * {
  margin-block: 0;
}

.o-cover > :first-child:not(.animation-container) {
  margin-block-start: 0;
}

.o-cover > :last-child:not(.animation-container) {
  margin-block-end: 0;
}

.o-cover > .animation-container {
  // margin-block: auto;
  margin-top: auto;
}
